export const environment = {
    apiUrl: 'https://development.router.flowrms.com/graphql',
    apiLogin: 'https://development.auth.flowrms.com',
    apiAutoPdf: 'https://development.ai.flowrms.com',
    apiPublic: 'https://development.api.flowrms.com/public',
    adminURL: 'https://development.admin.flowrms.com/graphql/',
    appUrl: 'https://development.app.flowrms.com',
    realm: 'app',
    clientId: 'app',
    clientSecret: '0vUIr4ZO9YoTL5Pb66Kxg5sJ23KI7NWU',
    production: false,
    development: false
};
